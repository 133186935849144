<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import { VMoney } from "v-money";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    requiredLegal() {
      if (this.client.type == "legal") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      alert: {
        type: "",
        message: "",
      },

      step: "1",

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },

      fee: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 6,
      },

      consumption: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
      },

      types: {
        natural: "Pessoa Física",
        legal: "Pessoa Jurídica",
      },

      client: {
        type: "",
        name: "",

        cellphone: "",
        email: "",

        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",

        legal: {
          name: "",
        },
      },

      companies: null,
      discounts: null,

      invoice: {
        type: "",
        company: "",
        discount: "",

        value: "R$ 0,00",
        fee: "0",
        consumption: "0",

        consumption_month_1: "",
        consumption_month_2: "",
        consumption_month_3: "",
        consumption_month_4: "",
        consumption_month_5: "",
        consumption_month_6: "",
        consumption_month_7: "",
        consumption_month_8: "",
        consumption_month_9: "",
        consumption_month_10: "",
        consumption_month_11: "",
        consumption_month_12: "",
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    client: {
      type: { required },

      name: { required },
      cellphone: { required },
      email: { required },

      zipcode: { required },
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },

      legal: {
        name: {
          required: requiredIf(function () {
            return this.requiredLegal;
          }),
        },
      },
    },
    invoice: {
      type: { required },
      company: { required },
      discount: { required },

      value: { required },
      fee: { required },
      consumption: { required },

      consumption_month_1: { required },
      consumption_month_2: { required },
      consumption_month_3: { required },
      consumption_month_4: { required },
      consumption_month_5: { required },
      consumption_month_6: { required },
      consumption_month_7: { required },
      consumption_month_8: { required },
      consumption_month_9: { required },
      consumption_month_10: { required },
      consumption_month_11: { required },
      consumption_month_12: { required },
    },
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    searchCep() {
      if (this.client.zipcode.length == 9) {
        api.get("cep/" + this.client.zipcode).then((response) => {
          if (response.data.city !== "") {
            if (response.data.address) {
              this.client.address = response.data.address;
            }
            if (response.data.district) {
              this.client.district = response.data.district;
            }
            this.client.city = response.data.city;
            this.client.state = response.data.state;
          }
        });
      }
    },
    nextToInvoice() {
      this.$v.client.$touch();
      if (this.$v.client.$invalid) {
        return;
      } else {
        this.getCompanies();
        this.step = 2;

        this.alert.type = "";
        this.alert.message = "";
      }
    },
    nextToResume() {
      this.$v.invoice.$touch();
      if (this.$v.invoice.$invalid) {
        return;
      } else {
        this.step = 3;

        this.alert.type = "";
        this.alert.message = "";
      }
    },
    getCompanies() {
      if (this.client.state) {
        api.get("energy/companies/" + this.client.state).then((response) => {
          if (response.data.status == "success") {
            this.companies = response.data.list;
          }
        });
      }
    },
    getDiscounts() {
      if (this.client.state && this.invoice.company) {
        api
          .get(
            "energy/discounts/" +
              this.client.state +
              "/" +
              this.invoice.company
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.discounts = response.data.list;
            } else {
              this.discounts = null;
            }
          });
      }
    },
    sendSimulation() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        api
          .post("energy/simulations", {
            client_type: this.client.type,
            client_name: this.client.name,
            client_cellphone: this.client.cellphone,
            client_email: this.client.email,
            client_zipcode: this.client.zipcode,
            client_address: this.client.address,
            client_number: this.client.number,
            client_complement: this.client.complement,
            client_district: this.client.district,
            client_city: this.client.city,
            client_state: this.client.state,
            client_legal_name: this.client.legal.name,

            invoice_type: this.invoice.type,
            invoice_company: this.invoice.company,
            invoice_discount: this.invoice.discount,
            invoice_value: this.invoice.value,
            invoice_fee: this.invoice.fee,
            invoice_consumption: this.invoice.consumption,
            invoice_consumption_month_1: this.invoice.consumption_month_1,
            invoice_consumption_month_2: this.invoice.consumption_month_2,
            invoice_consumption_month_3: this.invoice.consumption_month_3,
            invoice_consumption_month_4: this.invoice.consumption_month_4,
            invoice_consumption_month_5: this.invoice.consumption_month_5,
            invoice_consumption_month_6: this.invoice.consumption_month_6,
            invoice_consumption_month_7: this.invoice.consumption_month_7,
            invoice_consumption_month_8: this.invoice.consumption_month_8,
            invoice_consumption_month_9: this.invoice.consumption_month_9,
            invoice_consumption_month_10: this.invoice.consumption_month_10,
            invoice_consumption_month_11: this.invoice.consumption_month_11,
            invoice_consumption_month_12: this.invoice.consumption_month_12,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$v.$reset();

              this.$router.push(
                "/energy/simulations/" + response.data.simulation.id
              );

              this.alert.type = "alert-success";
              this.alert.message = response.data.message;
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }
          });
      }
    },
  },
  mounted() {},
  watch: {
    "invoice.company": function (v) {
      this.getDiscounts(v);
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Simular</h4>
        </div>
        <div v-if="alert.message" :class="'alert ' + alert.type">
          {{ t(alert.message) }}
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <div class="p-3">
              <template v-if="step == 1">
                <b-form-group :label="t('Tipo de Pessoa')">
                  <select v-model="client.type" class="custom-select mb-1">
                    <option value="">–</option>
                    <option value="natural">Pessoa Física</option>
                    <option value="legal">Pessoa Jurídica</option>
                  </select>
                  <div
                    v-if="$v.client.type.$error"
                    class="text-danger font-size-12 mt-1"
                  >
                    <span v-if="!$v.client.type.required">
                      {{ t("O tipo de pessoa é obrigatório.") }}
                    </span>
                  </div>
                </b-form-group>

                <template v-if="client.type == 'natural'">
                  <div class="divisor mt-5 mb-3">
                    <span>Dados Pessoais</span>
                  </div>

                  <b-form-group :label="t('Nome Completo')">
                    <b-form-input
                      v-model="client.name"
                      type="text"
                      :class="{ 'is-invalid': $v.client.name.$error }"
                    ></b-form-input>
                    <div
                      v-if="$v.client.name.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.name.required">
                        O nome é obrigatório.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Celular')">
                        <b-form-input
                          v-model="client.cellphone"
                          type="text"
                          :class="{ 'is-invalid': $v.client.cellphone.$error }"
                          v-mask="'(##) #####-####'"
                        ></b-form-input>
                        <div
                          v-if="$v.client.cellphone.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.cellphone.required">
                            O celular é obrigatório.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('E-mail')">
                        <b-form-input
                          v-model="client.email"
                          type="text"
                          :class="{ 'is-invalid': $v.client.email.$error }"
                        ></b-form-input>
                        <div
                          v-if="$v.client.email.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span
                            v-if="!$v.client.email.required"
                            class="text-danger font-size-12 mt-1"
                          >
                            O e-mail é obrigatório.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Endereço</span>
                  </div>

                  <b-form-group label="CEP">
                    <b-form-input
                      v-on:blur="searchCep()"
                      v-model="client.zipcode"
                      type="text"
                      :class="{ 'is-invalid': $v.client.zipcode.$error }"
                      autocomplete="off"
                      v-mask="'#####-###'"
                    ></b-form-input>
                    <div
                      v-if="$v.client.zipcode.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.zipcode.required"
                        >O CEP é obrigatório.</span
                      >
                      <span v-if="!$v.client.zipcode.invalid"
                        >O CEP informado é invalido.</span
                      >
                    </div>
                  </b-form-group>

                  <b-form-group label="Endereço">
                    <b-form-input
                      v-model="client.address"
                      type="text"
                      :class="{ 'is-invalid': $v.client.address.$error }"
                      autocomplete="off"
                    ></b-form-input>
                    <div
                      v-if="$v.client.address.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.address.required"
                        >O endereço é obrigatório.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Número">
                        <b-form-input
                          v-model="client.number"
                          type="text"
                          :class="{ 'is-invalid': $v.client.number.$error }"
                          autocomplete="off"
                        ></b-form-input>
                        <div
                          v-if="$v.client.number.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.number.required"
                            >O número é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Complemento">
                        <b-form-input
                          v-model="client.complement"
                          type="text"
                          :class="{ 'is-invalid': $v.client.complement.$error }"
                          autocomplete="off"
                        ></b-form-input>
                        <div
                          v-if="$v.client.complement.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.complement.required"
                            >O complemento é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group label="Bairro">
                    <b-form-input
                      v-model="client.district"
                      type="text"
                      :class="{ 'is-invalid': $v.client.district.$error }"
                      autocomplete="off"
                    ></b-form-input>
                    <div
                      v-if="$v.client.district.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.district.required"
                        >O bairro é obrigatório.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Cidade">
                        <b-form-input
                          class="bg-soft-info"
                          v-model="client.city"
                          type="text"
                          :class="{ 'is-invalid': $v.client.city.$error }"
                          autocomplete="off"
                          readonly="readonly"
                        ></b-form-input>
                        <div
                          v-if="$v.client.city.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.city.required"
                            >A cidade é obrigatória.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Estado">
                        <b-form-input
                          class="bg-soft-info"
                          v-model="client.state"
                          type="text"
                          :class="{ 'is-invalid': $v.client.state.$error }"
                          autocomplete="off"
                          readonly="readonly"
                          v-mask="'XX'"
                        ></b-form-input>
                        <div
                          v-if="$v.client.state.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.state.required"
                            >O estado é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-5 text-center">
                    <div></div>
                    <button
                      class="btn btn-default btn-lg"
                      v-on:click="
                        nextToInvoice();
                        toTop();
                      "
                    >
                      Avançar
                    </button>
                  </div>
                </template>

                <template v-else-if="client.type == 'legal'">
                  <div class="divisor mt-5 mb-3">
                    <span>Dados da Empresa</span>
                  </div>

                  <b-form-group :label="t('Razão Social')">
                    <b-form-input
                      v-model="client.legal.name"
                      type="text"
                      :class="{ 'is-invalid': $v.client.legal.name.$error }"
                    ></b-form-input>
                    <div
                      v-if="$v.client.legal.name.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.legal.name.required">
                        A razão social é obrigatória.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="divisor mt-5 mb-3">
                    <span>Dados do Representante</span>
                  </div>

                  <b-form-group :label="t('Nome Completo')">
                    <b-form-input
                      v-model="client.name"
                      type="text"
                      :class="{ 'is-invalid': $v.client.name.$error }"
                    ></b-form-input>
                    <div
                      v-if="$v.client.name.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.name.required">
                        O nome é obrigatório.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Celular')">
                        <b-form-input
                          v-model="client.cellphone"
                          type="text"
                          :class="{ 'is-invalid': $v.client.cellphone.$error }"
                          v-mask="'(##) #####-####'"
                        ></b-form-input>
                        <div
                          v-if="$v.client.cellphone.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.cellphone.required">
                            O celular é obrigatório.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('E-mail')">
                        <b-form-input
                          v-model="client.email"
                          type="text"
                          :class="{ 'is-invalid': $v.client.email.$error }"
                        ></b-form-input>
                        <div
                          v-if="$v.client.cellphone.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.email.required">
                            O e-mail é obrigatório.
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Endereço</span>
                  </div>

                  <b-form-group label="CEP">
                    <b-form-input
                      v-on:blur="searchCep()"
                      v-model="client.zipcode"
                      type="text"
                      :class="{ 'is-invalid': $v.client.zipcode.$error }"
                      autocomplete="off"
                      v-mask="'#####-###'"
                    ></b-form-input>
                    <div
                      v-if="$v.client.zipcode.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.zipcode.required"
                        >O CEP é obrigatório.</span
                      >
                      <span v-if="!$v.client.zipcode.invalid"
                        >O CEP informado é invalido.</span
                      >
                    </div>
                  </b-form-group>

                  <b-form-group label="Endereço">
                    <b-form-input
                      v-model="client.address"
                      type="text"
                      :class="{ 'is-invalid': $v.client.address.$error }"
                      autocomplete="off"
                    ></b-form-input>
                    <div
                      v-if="$v.client.address.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.address.required"
                        >O endereço é obrigatório.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Número">
                        <b-form-input
                          v-model="client.number"
                          type="text"
                          :class="{ 'is-invalid': $v.client.number.$error }"
                          autocomplete="off"
                        ></b-form-input>
                        <div
                          v-if="$v.client.number.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.number.required"
                            >O número é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Complemento">
                        <b-form-input
                          v-model="client.complement"
                          type="text"
                          :class="{ 'is-invalid': $v.client.complement.$error }"
                          autocomplete="off"
                        ></b-form-input>
                        <div
                          v-if="$v.client.complement.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.complement.required"
                            >O complemento é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group label="Bairro">
                    <b-form-input
                      v-model="client.district"
                      type="text"
                      :class="{ 'is-invalid': $v.client.district.$error }"
                      autocomplete="off"
                    ></b-form-input>
                    <div
                      v-if="$v.client.district.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.district.required"
                        >O bairro é obrigatório.</span
                      >
                    </div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Cidade">
                        <b-form-input
                          class="bg-soft-info"
                          v-model="client.city"
                          type="text"
                          :class="{ 'is-invalid': $v.client.city.$error }"
                          autocomplete="off"
                          readonly="readonly"
                        ></b-form-input>
                        <div
                          v-if="$v.client.city.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.city.required"
                            >A cidade é obrigatória.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Estado">
                        <b-form-input
                          class="bg-soft-info"
                          v-model="client.state"
                          type="text"
                          :class="{ 'is-invalid': $v.client.state.$error }"
                          autocomplete="off"
                          readonly="readonly"
                          v-mask="'XX'"
                        ></b-form-input>
                        <div
                          v-if="$v.client.state.$error"
                          class="text-danger font-size-12 mt-1"
                        >
                          <span v-if="!$v.client.state.required"
                            >O estado é obrigatório.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-5 text-center">
                    <div></div>
                    <button
                      class="btn btn-default btn-lg"
                      v-on:click="
                        nextToInvoice();
                        toTop();
                      "
                    >
                      Avançar
                    </button>
                  </div>
                </template>
              </template>
              <template v-else-if="step == 2">
                <div class="divisor mb-3">
                  <span>Dados de Consumo</span>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <b-form-group :label="t('Tipo de Ligação')">
                      <select v-model="invoice.type" class="custom-select mb-1">
                        <option value="">–</option>
                        <option value="MONOFÁSICO">MONOFÁSICO</option>
                        <option value="BIFÁSICO">BIFÁSICO</option>
                        <option value="TRIFÁSICO">TRIFÁSICO</option>
                      </select>
                      <div
                        v-if="$v.invoice.type.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.type.required"
                          >O tipo de ligação é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('Concessionária')">
                      <select
                        v-model="invoice.company"
                        class="custom-select mb-1"
                      >
                        <option value="">–</option>
                        <option
                          v-for="(row, index) in companies"
                          :key="index"
                          :value="row"
                        >
                          {{ row }}
                        </option>
                      </select>
                      <div
                        v-if="$v.invoice.company.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.company.required"
                          >A concessionária obrigatória.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('Desconto')">
                      <select
                        v-model="invoice.discount"
                        class="custom-select mb-1"
                      >
                        <option value="">–</option>
                        <option
                          v-for="(row, index) in discounts"
                          :key="index"
                          :value="index"
                        >
                          {{ row }}
                        </option>
                      </select>
                      <div
                        v-if="$v.invoice.discount.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.discount.required"
                          >O desconto é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <b-form-group :label="t('Valor da Fatura')">
                      <b-form-input
                        v-model="invoice.value"
                        v-money="money"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.value.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.value.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.value.required"
                          >O valor da fatura é obrigatória.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('Tarifa Energia (TE)')">
                      <b-form-input
                        v-model="invoice.fee"
                        v-money="fee"
                        type="text"
                        :class="{ 'is-invalid': $v.invoice.fee.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.fee.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.fee.required"
                          >O valor da tarifa de energia é obrigatória.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('Consumo em KW')">
                      <b-form-input
                        v-model="invoice.consumption"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption.required"
                          >O valor do consumo em KW é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="divisor mt-5 mb-3">
                  <span>Consumo de KW Por Mês</span>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <b-form-group :label="t('1º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_1"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_1.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_1.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_1.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('2º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_2"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_2.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_2.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_2.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('3º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_3"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_3.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_3.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_3.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('4 Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_4"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_4.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_4.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_4.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('5 Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_5"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_5.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_5.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_5.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('6º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_6"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_6.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_6.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_6.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('7º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_7"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_7.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_7.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_7.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('8º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_8"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_8.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_8.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_8.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('9º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_9"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_9.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_9.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_9.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('10º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_10"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_10.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_10.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_10.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('11º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_11"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_11.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_11.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_11.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group :label="t('12º Mês')">
                      <b-form-input
                        v-model="invoice.consumption_month_12"
                        v-money="consumption"
                        type="text"
                        :class="{
                          'is-invalid': $v.invoice.consumption_month_12.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="$v.invoice.consumption_month_12.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.invoice.consumption_month_12.required"
                          >Campo obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-5 text-center">
                  <button
                    class="btn btn-dark btn-lg mr-2"
                    v-on:click="
                      step = 1;
                      toTop();
                    "
                  >
                    Voltar
                  </button>
                  <button
                    class="btn btn-default btn-lg"
                    v-on:click="
                      nextToResume();
                      toTop();
                    "
                  >
                    Avançar
                  </button>
                </div>
              </template>
              <template v-else-if="step == 3">
                <template v-if="client.type == 'natural'">
                  <div class="divisor mb-3">
                    <span>Dados Pessoais</span>
                  </div>
                  <div class="mb-3 text-center">
                    <strong>{{ client.name }}</strong
                    ><br />
                    {{ client.cellphone }}<br />
                    {{ client.email }}
                  </div>
                </template>
                <template v-else>
                  <div class="divisor mt-5 mb-3">
                    <span>Dados da Empresa</span>
                  </div>
                  <div class="mb-3 text-center">
                    <strong>{{ client.legal.name }}</strong>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Dados do Representante</span>
                  </div>
                  <div class="mb-3 text-center">
                    <strong>{{ client.name }}</strong
                    ><br />
                    {{ client.cellphone }}<br />
                    {{ client.email }}
                  </div>
                </template>

                <div class="divisor mt-5 mb-3">
                  <span>Endereço</span>
                </div>
                <div class="mb-3 text-center">
                  {{ client.address }}, {{ client.number }}<br />
                  {{ client.district }}, {{ client.city }}/{{ client.state
                  }}<br />
                  {{ client.zipcode }}
                </div>

                <div class="divisor mt-5 mb-3">
                  <span>Dados de Consumo</span>
                </div>

                <div class="row justify-content-center text-center">
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Tipo de Ligação</span
                    ><br />
                    {{ invoice.type }}
                  </div>
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Concessionária</span
                    ><br />
                    {{ invoice.company }}
                  </div>
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Desconto</span
                    ><br />
                    {{ invoice.discount | points }}%
                  </div>
                </div>

                <div class="row justify-content-center text-center">
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Valor da Fatura</span
                    ><br />
                    {{ invoice.value }}
                  </div>
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Tarifa Energia</span
                    ><br />
                    {{ invoice.fee }}
                  </div>
                  <div class="col-6 col-md-3 mb-3">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >Consumo em KW</span
                    ><br />
                    {{ invoice.consumption }}
                  </div>
                </div>

                <div class="divisor mt-5 mb-3">
                  <span>Dados de KW Por Mês</span>
                </div>

                <div class="row justify-content-center text-center mb-1">
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >1º</span
                    ><br />
                    {{ invoice.consumption_month_1 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >2º</span
                    ><br />
                    {{ invoice.consumption_month_2 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >3º</span
                    ><br />
                    {{ invoice.consumption_month_3 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >4º</span
                    ><br />
                    {{ invoice.consumption_month_4 }} KW
                  </div>
                </div>

                <div class="row justify-content-center text-center mb-1">
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >5º</span
                    ><br />
                    {{ invoice.consumption_month_5 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >6º</span
                    ><br />
                    {{ invoice.consumption_month_6 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >7º</span
                    ><br />
                    {{ invoice.consumption_month_7 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >8º</span
                    ><br />
                    {{ invoice.consumption_month_8 }} KW
                  </div>
                </div>

                <div class="row justify-content-center text-center mb-3">
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >9º</span
                    ><br />
                    {{ invoice.consumption_month_9 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >10º</span
                    ><br />
                    {{ invoice.consumption_month_10 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >11º</span
                    ><br />
                    {{ invoice.consumption_month_11 }} KW
                  </div>
                  <div class="col-3 col-md-2">
                    <span
                      class="bg-soft-light rounded px-2 font-size-11 text-uppercase"
                      >12º</span
                    ><br />
                    {{ invoice.consumption_month_12 }} KW
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-5 text-center">
                  <button
                    class="btn btn-dark btn-lg mr-2"
                    v-on:click="
                      step = 2;
                      toTop();
                    "
                  >
                    Voltar
                  </button>
                  <button
                    class="btn btn-success btn-lg"
                    v-on:click="sendSimulation()"
                  >
                    Simular
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
